import React from 'react'
import Layout from 'src/components/Layout'
import NonStretchedImage from 'src/components/NonStretchedImage'

import styles from './styles.module.css'

const BlogPost = ({ pageContext }) => {
  const { data } = pageContext
  const {
    cockpitCreated,
    title: { value: title },
    content: {
      value: { childMarkdownRemark: contentMarkdownRemark },
    },
    author: {
      value: {
        firstName: { value: firstName },
        lastName: { value: lastName },
        title: { value: authorTitle },
      },
    },
  } = data
  return (
    <Layout pageContext={pageContext}>
      <div className={styles.blogPage}>
        <div className={styles.title}>{title}</div>
        <div className={styles.headerAuthorContainer}>
          {data.author?.value?.avatar?.value?.childImageSharp?.fluid && <NonStretchedImage
            className={styles.smallAuthorImage}
            fluid={data.author?.value?.avatar?.value?.childImageSharp?.fluid}
          />}
          <div className={styles.authorText}>
            <div>
              {firstName} {lastName}
            </div>
            <div>{cockpitCreated}</div>
          </div>
        </div>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: contentMarkdownRemark.html }}
        />
        {data.image?.value?.childImageSharp?.fluid && <NonStretchedImage
          className={styles.postImage}
          fluid={data.image?.value?.childImageSharp?.fluid}
        />}
        <div className={styles.footerAuthorContainer}>
          {data.author?.value?.avatar?.value?.childImageSharp?.fluid && <NonStretchedImage
            className={styles.bigAuthorImage}
            fluid={data.author?.value?.avatar?.value?.childImageSharp?.fluid}
          />}
          <div className={styles.authorText}>
            <div>
              {firstName} {lastName}
            </div>
            <div>{authorTitle}</div>
            <div>{data.author?.value?.description?.value}</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
